import "../css/Footer.css";
import wp from "../../img/whatsapp.png";
import fb from "../../img/facebook.png";
import inst from "../../img/instagram.png";
import logo from "../../img/Ayoub logo.png";

const Footer = () => {
  return (
    <div id="contact-us">
      <footer id="footer">
        <a
          href="https://api.whatsapp.com/send?phone=96171007004"
          target="_blank"
        >
          <img src={wp} id="wp" />
        </a>
        <a
          href="https://www.facebook.com/chadi.ayoub.10?mibextid=ZbWKwL"
          target="_blank"
        >
          <img src={fb} id="fb" />
        </a>
        <img src={inst} id="inst" />
        <br />
        <img src={logo} id="ayoubLogo" />
        <p id="copyright">Copyright &copy;PrograLine 2022</p>
      </footer>
    </div>
  );
};
export default Footer;

import "../css/HomePage.css";
import face from "../../img/b-face.png";

const HomePage = () => {
  return (
    <div className="HomeContainer" id="hp">
      <br />
      <br />
      <div id="info">
        <div className="section1">
          <article id="whyAyoub">
            <h1 id="header">WHY AYOUB BEAUTY CLINIC & SPA CENTER?</h1>
            <p id="text">
              Our passion for beauty, perfectionism, and precision together with
              our history of more than 10 years in the beauty industry make us
              leaders in the field. All services provided at Ayoub beauty Clinic
              & Spa Center are supervised by our team of highly trained
              professionals. Our multidisciplinary team of experts in surgery,
              dermatology and aesthetics supports Dr Ayoub in bringing the
              desired aesthetic results. Our front office staff is always ready
              to accommodate any of your requests.
            </p>
          </article>
          <img id="face" src={face} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;

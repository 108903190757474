import Navbar from "./components/js/Navbar";
import HomePage from "./components/js/HomePage";
import OurStory from "./components/js/OurStory";
import Tabs from "./components/js/Tabs";
import Team from "./components/js/Team";
import Footer from "./components/js/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <HomePage />
      <OurStory />
      <Tabs />
      <Team />
      <br />
      <Footer />
    </div>
  );
}

export default App;

import "../css/Navbar.css";
import { useState } from "react";
import ayoub from "../../img/Ayoub.png";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import OurStory from "./OurStory";
import Tabs from "./Tabs";
import Team from "./Team";
import Footer from "./Footer";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 10 && window.location.pathname === "/") {
      setNavbar(true);
    } else if (window.location.pathname !== "/") {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <div id="navbar">
      <BrowserRouter>
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg fixed-top bg-color"
              : "navbar navbar-expand-lg fixed-top bg-transparent transparent"
          }
        >
          <div className="container-fluid" id="cont">
            <img src={ayoub} id="logo" />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#our-story">
                    Our Story
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#highlights">
                    Highlights
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#our-team">
                    Our Team
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact-us">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <Routes>
          <Route exact path="#" element={<HomePage />} />
          <Route path="#our-story" element={<OurStory />} />
          <Route path="#highlights" element={<Tabs />} />
          <Route path="#our-team" element={<Team />} />
          <Route path="#contact-us" element={<Footer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Navbar;

import "../css/HomePage.css";
import Lottie from "react-lottie";
import clinic from "../../img/clinic.json";

const OurStory = () => {
  const ayoubClinic = {
    loop: true,
    autoplay: true,
    animationData: clinic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="HomeContainer" id="hp">
      <div className="section2" id="our-story">
        <div id="clinic">
          <Lottie options={ayoubClinic} isClickToPauseDisabled />
        </div>
        {/* <img src={dr} id="dr" left /> */}
        <article id="ourMission">
          <h1 id="header">Our Story</h1>
          <p id="text">
            Ayoub beauty Clinic & Spa Center is a professional medical center,
            where we provide personalized high quality services. We believe that
            beauty should be accessible to everyone, and we are striving to
            continue making it a reality at our medical spa. At Ayoub beauty
            Clinic & Spa Center we offer state-of-the- art laser treatments,
            laser hair removal, innovative aesthetic procedures, lip
            augmentation, non-surgical rhinoplasty, rejuvenating injections with
            the best НА product in the world, wrinkle prevention and treatment
            with Botox and other neuromodulators, revitalizing facials, chemical
            peels, facial and body sculpting and all surgical beauty services.
            We serve our clients
          </p>
        </article>
      </div>
    </div>
  );
};
export default OurStory;

import "../css/Tabs.css";
import { useState } from "react";
import lips1 from "../../img/surgs/face/lips/lip1.jpeg";
import lips2 from "../../img/surgs/face/lips/lip2.jpeg";
import lips3 from "../../img/surgs/face/lips/lip3.jpeg";
import lips4 from "../../img/surgs/face/lips/lip4.jpeg";
import lips5 from "../../img/surgs/face/lips/lip5.jpeg";
import lips6 from "../../img/surgs/face/lips/lip6.jpeg";
import lips7 from "../../img/surgs/face/lips/lip7.jpeg";
import lips8 from "../../img/surgs/face/lips/lip8.jpeg";
import lips9 from "../../img/surgs/face/lips/lip9.jpeg";
import lips10 from "../../img/surgs/face/lips/lip10.jpeg";
import lips11 from "../../img/surgs/face/lips/lip11.jpeg";
import lips12 from "../../img/surgs/face/lips/lip12.jpeg";
import lips13 from "../../img/surgs/face/lips/lip13.jpeg";

const Tabs = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div id="highlights">
      <h1 id="hi-header">Highlights</h1>
      <hr id="hr" />
      <div className="container">
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            Face
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            Breast
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            Belly
          </button>

          <button
            className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(4)}
          >
            Back
          </button>

          <button
            className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(5)}
          >
            Bottom
          </button>
        </div>

        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <h2>Face</h2>
            <hr />
            <div id="pics">
              <div className="card" id="lips">
                <img src={lips1} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips2} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips3} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips4} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips5} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips6} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips7} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips8} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips9} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips10} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips11} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips12} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
              <div className="card" id="lips">
                <img src={lips13} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title" id="lips-title">
                    Lips
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <h2>Breast</h2>
            <hr />
            <p>Add breast data</p>
          </div>

          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <h2>Belly</h2>
            <hr />
            <p>Add belly data</p>
          </div>

          <div
            className={
              toggleState === 4 ? "content  active-content" : "content"
            }
          >
            <h2>Back</h2>
            <hr />
            <p>Add back data</p>
          </div>

          <div
            className={
              toggleState === 5 ? "content  active-content" : "content"
            }
          >
            <h2>Bottom</h2>
            <hr />
            <p>Add bottom data</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tabs;

import "../css/Team.css";
import Lottie from "react-lottie";
import team from "../../img/team.json";

const Team = () => {
  const doctors = {
    loop: true,
    autoplay: true,
    animationData: team,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="TeamContainer" id="our-team">
      <div id="drs-lot">
        <Lottie options={doctors} isClickToPauseDisabled />
      </div>
      <h2 id="team-header">Our Team</h2>
      <hr id="hr" />

      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="owners">
              <div className="card" id="shady">
                <div className="card-body">
                  <h5 className="card-title" id="card-title">
                    Dr. Chadi Ayoub
                  </h5>
                  <hr />
                  <p className="card-text">The owner and general manager.</p>
                </div>
              </div>

              <div className="card" id="ghassan">
                <div className="card-body">
                  <h5 className="card-title" id="card-title">
                    Dr. Ghassan Nabbout
                  </h5>
                  <hr />
                  <p className="card-text">General surgeon</p>
                </div>
              </div>

              <div className="card" id="nabil">
                <div className="card-body">
                  <h5 className="card-title" id="card-title">
                    Dr. Nabil Zaghloul
                  </h5>
                  <hr />
                  <p className="card-text">Dermatologist.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
